<template>
  <div id="payment-subscriptions-list">
    <v-card>
      <v-card-title>Payment subscriptions list</v-card-title>
      <v-row class="px-2 ma-0">
        <!-- status filter -->
        <v-col cols="12" :sm="isAdmin ? 3 : 4">
          <v-select
            class="payment-subscriptions-status-filter"
            v-model="statusFilter"
            placeholder="Select Status"
            :items="paymentSubscriptionStatus"
            item-text="title"
            item-value="value"
            outlined
            dense
            clearable
            hide-details
          ></v-select>
        </v-col>
        <v-col v-if="isAdmin" cols="12" sm="3">
          <v-autocomplete
            class="payment-subscriptions-company-filter"
            v-model="companyFilter"
            :items="companies"
            item-value="id"
            item-text="name"
            dense
            filled
            outlined
            clearable
            hide-details
            label="Select company"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="3">
          <v-dialog ref="dialog" v-model="paymentSubscriptionDateModal" :return-value.sync="paymentSubscriptionDateFilter" width="290px">
            <template #activator="{ on, attrs }">
              <v-text-field
                v-model="paymentSubscriptionDateFilter"
                class="payment-event-date-filter"
                label="Select created date"
                outlined
                dense
                clearable
                hide-details
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="paymentSubscriptionDateFilter" class="payment-event-date-picker" scrollable range>
              <v-row>
                <v-col cols="12">
                  <v-select
                    placeholder="Select presets"
                    :items="presetsForPaymentSubscriptionDatePicker"
                    item-text="title"
                    item-value="value"
                    outlined
                    dense
                    clearable
                    hide-details
                    @change="selectedPaymentSubscriptionPresetDate"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row style="text-align: end">
                <v-col cols="12">
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="$refs.dialog.save((paymentSubscriptionDateFilter = null))"> Cancel </v-btn>
                  <v-btn text color="primary" @click="$refs.dialog.save(paymentSubscriptionDateFilter)"> OK </v-btn>
                </v-col>
              </v-row>
            </v-date-picker>
          </v-dialog>
        </v-col>
      </v-row>

      <v-divider class="mt-4 mb-4"></v-divider>

      <v-row>
        <v-col cols="6">
          <v-card-text class="d-flex align-center flex-wrap pb-0">
            <v-text-field
              v-model="searchQuery"
              placeholder="Search"
              outlined
              hide-details
              dense
              class="chargeback-event-search-query user-search me-3 mb-4"
            >
            </v-text-field>
            <v-spacer></v-spacer>
          </v-card-text>
        </v-col>
      </v-row>
      <!-- table -->
      <v-data-table
        :options.sync="options"
        :headers="tableColumns"
        :items="paymentSubscriptionsListTable"
        :server-items-length="totalPaymentSubscriptionsListTable"
        :item-class="paymentSubscriptionItemClass"
        :loading="loading"
        :footer-props="{ 'items-per-page-options': [30, 45, 50] }"
      >
        <!-- id -->
        <template #[`item.subscriptionToken`]="{ item }">
          {{ splitSubscriptionToken(item.subscriptionToken) }}
        </template>
        <!-- status -->
        <template #[`item.status`]="{ item }">
          <v-chip
            small
            :color="resolvePaymentSubscriptionStatusVariant(item.status)"
            :class="`${resolvePaymentSubscriptionStatusVariant(item.status)}--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            {{ resolvePaymentSubscriptionStatusName(item.status) }}
          </v-chip>
        </template>
        <template #[`item.merchant`]="{ item }">
          <div>{{ item.website.name }} ({{ item.website.company.accountName }})</div>
        </template>
        <template #[`item.paymentProvider`]="{ item }">
          <v-img
            v-if="item.paymentProvider"
            :src="require(`@/assets/images/svg/${item.paymentProvider}-${$vuetify.theme.dark ? 'dark' : 'light'}.svg`)"
            width="40px"
          ></v-img>
        </template>
        <template #[`item.createdAt`]="{ item }">
          <div style="width: 90px">{{ item.createdAt | moment(`${getDateFormat(item.createdAt)}`) }}</div>
        </template>
        <template #[`item.expiresAt`]="{ item }">
          <div style="width: 90px">{{ item.expiresAt | moment(`${getDateFormat(item.expiresAt)}`) }}</div>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{ item }">
          <v-menu bottom left>
            <template #activator="{ on, attrs }">
              <v-btn class="payment-subscriptions-list-item-show-more-actions" icon v-bind="attrs" v-on="on">
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item class="payment-subscriptions-list-item-details" :to="{ name: 'payment-subscription-view', params: { id: item.id } }">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>Details</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mdiCashRefund, mdiDotsVertical, mdiFileDocumentOutline } from '@mdi/js';
import usePaymentSubscriptions from '@/views/payments/payment-subscriptions/usePaymentSubscriptions';
import Roles from '@/enums/roles.enum';
import { getDateFormat } from '@core/utils/filter';

export default {
  setup() {
    const {
      status,
      paymentSubscriptionStatus,
      paymentSubscriptionStatusEnum,
      paymentSubscriptionServiceEnum,
      companies,
      paymentSubscriptionsListTable,
      tableColumns,
      searchQuery,
      statusFilter,
      cardTypeFilter,
      cardTypeOptions,
      paymentSubscriptionDateFilter,
      paymentSubscriptionDateModal,
      presetsForPaymentSubscriptionDatePicker,
      selectedPaymentSubscriptionPresetDate,
      loading,
      options,
      totalPaymentSubscriptionsListTable,
      companyFilter,
      fetchPaymentSubscriptions,
      resolvePaymentSubscriptionStatusVariant,
      resolvePaymentSubscriptionStatusName,
    } = usePaymentSubscriptions();

    const paymentSubscriptionItemClass = () => {
      return 'payment-subscription-item';
    };

    const splitSubscriptionToken = subscriptionToken => subscriptionToken.split('-').shift();

    return {
      status,
      paymentSubscriptionStatus,
      paymentSubscriptionStatusEnum,
      paymentSubscriptionServiceEnum,
      companies,
      paymentSubscriptionsListTable,
      tableColumns,
      searchQuery,
      statusFilter,
      companyFilter,
      cardTypeFilter,
      cardTypeOptions,
      paymentSubscriptionDateFilter,
      paymentSubscriptionDateModal,
      presetsForPaymentSubscriptionDatePicker,
      totalPaymentSubscriptionsListTable,
      loading,
      options,
      splitSubscriptionToken,
      fetchPaymentSubscriptions,
      resolvePaymentSubscriptionStatusVariant,
      resolvePaymentSubscriptionStatusName,
      paymentSubscriptionItemClass,
      getDateFormat,
      selectedPaymentSubscriptionPresetDate,
      icons: {
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiCashRefund,
      },
    };
  },
  computed: {
    isAdmin() {
      return this.$store.state.auth.profile && this.$store.state.auth.profile.role === Roles.ADMIN;
    },
  },
};
</script>

<style lang="scss">
.payment-event-date-picker {
  > .v-card__actions {
    display: block !important;
    > div.row:last-child {
      text-align: end;
    }
  }
}
.payment-subscriptions-company-filter {
  > .v-input__control > .v-input__slot {
    background: transparent !important;
  }
  .v-label {
    font-size: 14px;
    color: rgba(94, 86, 105, 0.38);
  }
}
@import '@core/preset/preset/apps/user.scss';
</style>
